import React from 'react';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import ReactDOM from 'react-dom/client';

import './index.css';
import App from './App';
import Draw from './Draw';
import Search from './Search';
import Canvas from './Canvas';

import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route exact path="/" element={<Search/>}/>
        <Route exact path="/search/" element={<Search/>}/>
        <Route exact path="/draw/" element={<Draw/>}/>
        <Route exact path="/app/" element={<App/>}/>
        <Route exact path="/canvas/" element={<Canvas/>}/>
      </Routes>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
