import React, { useState, useEffect } from 'react';
import useFetch from "react-fetch-hook";
import { useLocation, withRouter } from 'react-router-dom';
import queryString from 'query-string';
import SearchInput, {createFilter} from 'react-search-input'

import logo from './logo.svg';
import loader from './loader.svg';
import data from './data.json';
import higlight from './higlight.json';
import collections from './collections.json';
import './Search.css';
import useWindowDimensions from './hooks/useWindowDimensions';

// 
// Solr query sanitization
//
function solrSanitizeQuery(query) {
  let tokens = ["+AND+", "+AND"]
  for (let i=0; i<tokens.length; i++) {
    if (query.endsWith(tokens[i])) {
      query = query.substring(0, query.lastIndexOf(tokens[i]))
    }
  }
  return query
}

//
// Solr querying
//
function getURL(collection, query) {
  query = solrSanitizeQuery(query)

  let url = "/solr/" + collection.name + "/select?indent=true&q.op=OR&facet=true"
  let facets = Object.keys(collection.facets)

  for (let i=0; i<facets.length; i++) {
    url += "&facet.field=" + facets[i] + "&facet.limit=" + collection.facets[facets[i]]
  }

  if (query.indexOf(" ") != -1) {
    query = "\"" + query + "\""
  }

  url += "&sort=" + collection.sort
      +  "&rows=" + collection.rows
      + "&q="
      + ((query == "all" || query == "" || query == "\"") ? "*:*" : (collection.search + ":" + query))
  // console.log(url)
  return url
}

//
// Aggregate daily to monthly facet counts
//
function aggregateFacedCounts(facets, start) {
  let years = 1 + Math.floor(dateOffset(new Date(), start)/12)
  let counts = Array(12*years).fill(0)
  for (let i=0; i<facets.length; i+=2) {
      if (facets[i+1] > 0) {
        let offset = dateOffset(facets[i], start)
        counts[offset]+=facets[i+1]
      }      
    }
    return counts
}

function dateOffset(date, start) {
  let current_date = new Date(date)
  let past_date = new Date(start)
  return (current_date.getFullYear()*12 + current_date.getMonth()) - (past_date.getFullYear()*12 + past_date.getMonth())
}

//
// Timeline graph
//
function genGraph(facets, baseline, width, height) {

  let counts = aggregateFacedCounts(facets, '1/1/2014');

  let results = []
  let months = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"]
  for (let i=0; i<12; i++) {
    results.push(<text x={70+i*80} y={5} className="small" fontFamily="IBM Plex Mono" 
      fontSize={10} fill="rgba(255,255,255,0.5)" textAnchor="middle" dominantBaseline="middle">{months[i].toUpperCase()}</text>)
  }

  for (let i=0; i<9; i++) {
    results.push(<text x={15} y={45+i*50} className="small" fontFamily="IBM Plex Mono" 
      fontSize={10} fill="rgba(255,255,255,0.5)" textAnchor="middle" dominantBaseline="middle">{2014+i}</text>)
  }

  for (let i=0; i<12; i++) {
    for (let j=0; j<9; j++) {
      let offset = j*12+i
      if (counts[offset] > 0) {
        let pct = counts[offset]/baseline[offset]
        let r = Math.min(1 + pct*250,25)
        results.push(<circle cx={70+i*80} cy={40+j*50} r={r} fill="#F1AA27"/>)

        let xoffset = 70+i*80 + 40
        let fill = "rgba(99,100,102,0.5)"

        results.push(<text x={xoffset} y={40+j*50} className="small" fontFamily="IBM Plex Mono" 
          fontSize={10} fontWeight={500} fill={fill} textAnchor="middle" dominantBaseline="middle">{Math.ceil(pct*100) + "%"}</text>)
      }
    }
  }

  return results
}

//
// Top Token Facets
//
function renderTokens(tokens, numFound, query, setQuery) {
  let result = []
  let excluded = ["fast", "s.mj.run", "8k", "4k", "ar", "9", "upscaled", "q", "max", "hd", "3","png", 
                  "i.mj.run", "0_0", "upbeta", "0_3", "test", "0_1", "0_2", "quality", "highly", "beta", "imagine", "super", "testp"]
  // let higlight = ["cyberpunk", "futuristic", "neon", "cinematic", "alien", "robot", "future", "steampunk", "space", "fantasy", "dragon", 
  //                 "anime", "sword", "samurai", "warrior", "armor", "knight", "medieval", "japanese", "demon", "galaxy","fighting", "war", "giant",
  //                 "magic", "horror", "hell", "ancient", "castle", "planet", "king", "cat", "death", "angel", "dog"]
  let higlight = []
  if (tokens) {
    for (let i=0; i<Math.min(tokens.length,1000); i+=2) {
      let name = tokens[i]
      if (excluded.includes(name.toLowerCase())) {
        continue
      }
      if (tokens[i+1] > Math.max(5, numFound/150)) {
        // result.push(<div className="TopToken" onClick={() => {console.log(tokens[i]); fetch("http://localhost:5000/add?term=" + tokens[i])} }>{tokens[i]}</div>)
        let tokenClass="TokenName"
        if (higlight.includes(tokens[i].toLowerCase())) {
          tokenClass += " TokenHL"
        }

        result.push(<div className="TopToken" >
                         <div className={tokenClass} onClick={() => setQuery(tokens[i]) }>{tokens[i]} </div>
                         <div className="TokenCount"
                            style={{"width": 1 + Math.min(2,(Math.floor(numFound/100)*0.25)) + "em"}}
                            onClick={() => setQuery(query + "+AND+" + tokens[i])}>
                            <span>{tokens[i+1].toLocaleString("en-US")}</span>
                          </div>
                         </div>)
        if (result.length > 100) {
          break
        }
      }
    }
  }
  return(
    <div className="TokenPanel">{result}</div>
  )
}

// token filter
function removeToken(terms, token) {
  return terms.filter(value => value !== token).join("+AND+");
}

// search query rendering
function renderQuery(query,setQuery) {
  let terms = query.split("+AND+")
  terms = terms.filter((w) => w.length > 0) // remove empty tokens
  let result = []
  for (let i=0; i<terms.length; i++) {
    result.push(<div className="QueryToken">{terms[i]} 
                      <div className="RemoveToken" 
                            onClick={() => setQuery(removeToken(terms, terms[i]))}
                            >x</div>
                    </div>)
  }
  return( <div className="QueryTokens">{result}</div>)
  
}

// solr sanitize
function sanitize(string) {
  return string.replaceAll("\*","").split("<@")[0].replaceAll("Upscaled by","").replaceAll("Upscaled -","").replaceAll("-","").trim()
}

function polar_to_cartesian(r, theta, offset) {
  return { 
    x: offset.x + r * Math.cos(degrees_to_radians(theta)),
    y: offset.y + r * Math.sin(degrees_to_radians(theta))
  };
}

function cartesian_to_polar({x, y}) {
  return { 
    r: Math.sqrt(x * x + y * y), 
    theta: Math.atan2(y, x) 
  };
}


function radians_to_degrees(radians)
{
  return radians * (180/Math.PI);
}

function degrees_to_radians(degrees)
{
  return degrees * (Math.PI/180);
}

function excludedDomain(domain) {
  return domain && (domain.includes("hackaday") || domain.includes("supplyframe"))
}

function renderLinks(links, numFound, width, height) {
  let results = []
  for (let i=0; i<360; i+=3) {
    let center = {x: width/2, y: 500}
    let p1 = polar_to_cartesian(120+30*Math.random(), i, center)
    let diameter = 180*(1+(i%75)/90+ Math.random()/5)
    let p2 = polar_to_cartesian(diameter, i, center)

    if (!links || (i*2 + 1 > links.length)) {
      break
    }

    let entry = {
      "domain": links[i*2],
      "count": links[i*2+1]
    }

    if (excludedDomain(entry.domain)) {
      continue
    }

    let size = 1 + Math.log(entry.count)

    results.push(<line x1={p1.x} y1={p1.y} x2={p2.x} y2={p2.y} stroke="#565659" />)
    results.push(<circle cx={p1.x} cy={p1.y} r={2} fill="#fff"/>)
    results.push(<circle cx={p2.x} cy={p2.y} r={size} fill="#FFBF2A"/>)

    let pt = polar_to_cartesian(130, i, center)
    let pt2 = polar_to_cartesian(diameter+20, i, center)

    if (entry.count == 0) {
      continue
    }

    if (i > 90 && i < 270) {
      results.push(<text x={pt2.x} y={pt2.y} className="small" fontFamily="IBM Plex Mono" 
        fontSize={10} fill="#909092" textAnchor="end" dominantBaseline="middle"
        transform={"rotate(" + i + " " + pt2.x + " " + pt2.y + ") rotate(" + -180 + " " + pt2.x + " " + pt2.y + ")"}>{entry.domain.substring(0,30)}</text>)
    }  else {
      results.push(<text x={pt2.x} y={pt2.y} className="small" fontFamily="IBM Plex Mono" 
        fontSize={10} fill="#909092" textAnchor="start" dominantBaseline="middle"
        transform={"rotate(" + i + " " + pt2.x + " " + pt2.y + ") "}>{entry.domain.substring(0,30)}</text>)

    }

  }
  return(<svg height={height*2.5} width={width}>
        {results}
        </svg>
        )
}

//
// Render article content
// 
function renderContent(content, maxChars) {
  let results = []
  let tokens = content.split(" ")  
  for (let i=0; i<Math.min(tokens.length,500); i++) {
    if (higlight.includes(tokens[i].toLowerCase().replaceAll(",","").replaceAll(".",""))) {
        results.push(<span className="Higlight">{tokens[i]}</span>)
    } else {
      results.push(<span onClick={() => {console.log(tokens[i]); fetch("http://localhost:5000/add?term=" + tokens[i])} }>{tokens[i]}</span>)
    }
    if (i > maxChars){
      break
    }
  }
  return results
  // return (tokens.join(" ").substring(0,maxChars)).split(" ").slice(0,-1).join(" ") + "..."
}

function isPrefix(key, cache) {
  let keys = Object.keys(cache)
  for (let i=0; i<keys.length; i++) {
    if (keys[i] != key && keys[i].startsWith(key)) {
      return true
    }
  }
  return false
}

// get the oldest date in facet collection
function getStartDate(facets) {
  let start = new Date(facets[0]);
  for (let i=0; i<facets.length; i++) {
    if (facets[i].length > 0) {
      let date = new Date(facets[i])
      if (date < start) {
        start = date
      }
    }
  }
  return start
}

function getMax(counts) {
  let max = 0
  for (let i=0; i<counts.length; i++) {
    if (counts[i] > max) {
      max = counts[i]
    }
  }  
  return max
}

//
// Line graph rendering
//
function renderGraph(facets, baseline, width, height, cache, saveHistory) {
  let results = []

  // let startDate = new Date("September 8, 2004")
  let startDate = getStartDate(facets);  
  let counts = aggregateFacedCounts(facets, startDate);
  let max = getMax(counts)
  let renderCeiling = false

  let nYears = Math.ceil(counts.length/12)
  let floor = height * 0.8;
  
  // let palette = ["#9880FF", "#97B8FF"]
  let palette = ["#F0A928", "#D1A000"]

  let step = Math.floor(width/counts.length) + 0.6
  let xOffset = 2*step

  // legend
  for (let i=1; i<=counts.length; i++) {
    results.push(<line x1={i*step} y1={0} x2={i*step} y2={height*0.8} stroke="#242528" strokeWidth={1} />)
  }

  if (renderCeiling) {
    results.push(<line x1={0} y1={Math.max(floor-max*5,0)} x2={nYears*step*12} y2={Math.max(floor-max*5,0)} stroke="rgba(255,255,255,0.1)" strokeWidth={0.5} />)
    results.push(<text x={xOffset} y={Math.max(floor-max*5,0)-10} className="small" fontFamily="IBM Plex Mono" fontSize={10} fill="#F0A928">{max} articles</text>)
  }


  for (let i=0; i<nYears; i++) {
        if (width < 500 && i%2 == 0) {
          continue
        }

    results.push(<text x={xOffset + i*step*12+step} y={height*0.88} className="small" fontFamily="IBM Plex Mono" 
      fontSize={10} fill="#909092" textAnchor="middle" dominantBaseline="middle">{startDate.getFullYear()+i}</text>)
  }

  // content

  if (!saveHistory) {
    for (let i=1; i<Math.min(counts.length-12,counts.length); i++) {
      results.push(<line x1={xOffset + (i-1)*step} y1={Math.max(floor-counts[i-1]*5,10)} x2={xOffset + (i)*step} y2={Math.max(floor-counts[i]*5,10)} stroke={palette[0]} strokeWidth={1} />)
      if (counts[i] > 0) {
        results.push(<circle cx={xOffset + i*step} cy={Math.max(floor-counts[i]*5,10)} r="3" fill={palette[1]} />)
      }
    }
  } else {

    let colors = ["#1C73E8", "#F0A928", "#FF8080", "#CF92C9", "#8CC98F"]

    let idx = 0;
    for (let key in cache) {

      if (key == "*:*" || isPrefix(key,cache)) {
        continue
      }

      let cc = aggregateFacedCounts(cache[key], startDate);

      // render legend
      results.push(<circle cx={30 + 75*idx - 10} cy={20} r="3" fill={colors[idx%colors.length]} />)
      results.push(<text x={30 + 75*idx} y={20} fontFamily="IBM Plex Mono" 
        fontSize={10} fill="rgba(255,255,255,0.5)"dominantBaseline="middle">{key.toUpperCase()}</text>)

      for (let j=1; j<cc.length; j++) {
        results.push(<line x1={xOffset + (j-1)*step} y1={Math.max(floor-cc[j-1]*5,0)} x2={xOffset + j*step} y2={Math.max(floor-cc[j]*5,0)} stroke={colors[idx%colors.length]} strokeWidth={1} />)
        if (cc[j] > 0) {
          results.push(<circle cx={xOffset + j*step} cy={Math.max(floor-cc[j]*5,0)} r="2" fill={colors[idx%colors.length]} />)
        }
      }
      idx++
    }

  }

  return (
      <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg">                  
          {results}
        </svg>
    )
}

function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

function isStopword(word) {
  const stopwords = new Set(["a","the","and", "an", "as", "in", "of", "the", "is", "this", "but", "to", "we", "from", "for", "it’s",
                              "2000", "2001", "2020", "2021", "2022", "do", "that", "they", "our", "your", "how", "on", "what",
                              "may", "with", "uses", "we’re", "google", "more", "where", "does", "gets", "through", "like", "aren’t", "don’t"])
  
  if (word.length < 4 || isNumeric(word)) {
    return true
  }

  if (!/^[a-zA-Z]+$/.test(word)) {
    return true
  }

  word = word.replaceAll("'","\'")

  return stopwords.has(word.toLowerCase().trim())
}

function matrix(n) {
  const results = [];
for (let i = 0; i < n; i++) {
    results.push([]);
  }
let counter = 1;
  let startColumn = 0;
  let endColumn = n - 1;
  let startRow = 0;
  let endRow = n - 1;
  while (startColumn <= endColumn && startRow <= endRow) {
    // Top row
    for (let i = startColumn; i <= endColumn; i++) {
      results[startRow][i] = counter;
      counter++;
    }
    startRow++;
// Right column
    for (let i = startRow; i <= endRow; i++) {
      results[i][endColumn] = counter;
      counter++;
    }
    endColumn--;
// Bottom row
    for (let i = endColumn; i >= startColumn; i--) {
      results[endRow][i] = counter;
      counter++;
    }
    endRow--;
// start column
    for (let i = endRow; i >= startRow; i--) {
      results[i][startColumn] = counter;
      counter++;
    }
    startColumn++;
  }
return results;
}

//
// Keyword Graph Rendering
//
function renderKeywords(docs, field, width, height) {
  let results = []
  let counts = {}

  // compute token freqencies
  for (let i=0; i<docs.length; i++) {
    let tokens = String(docs[i][field]).split(" ")
    tokens = tokens.filter(key => !isStopword(key))
    for (let j=0; j<tokens.length; j++) {    
      let token = tokens[j]
      if (!counts[token]) {
        counts[token] = 1
      } else {
        counts[token]+= 1
      }
    }
  }

  // get high freq tokens
  let freqTokens = Object.keys(counts).filter(key => (counts[key] > 2))
  freqTokens.sort(function(a,b) { return counts[b] - counts[a]})
  freqTokens = freqTokens.slice(0,30)

  // sort by count
  freqTokens.sort((a, b) => (counts[b] - counts[a]))

  // update coocurrence of high frequency tokens
  let cooccurrence = []
  for (let i=0; i<docs.length; i++) {
    let tokens = String(docs[i][field]).split(" ").filter(key => ((counts[key] > 1) && !isStopword(key)))
    cooccurrence.push(tokens)
  }

  // render

  let textWidth = 175
  let gridHeight = 70
  let coords = {}

  // setup 5x5 spiral grid
  let dim = Math.ceil(Math.sqrt(freqTokens.length))
  let spiral = matrix(dim)
  let xoffset = width/dim
  for (let i=0; i<dim; i++) {
    for (let j=0; j<dim; j++) {
      if (spiral[i][j] < freqTokens.length + 1) {
        let token = freqTokens[spiral[i][j]-1]
        let c = {"x": 50+i*xoffset, "y": 50+j*xoffset/2}
        coords[token] = c
        results.push(<text x={c.x} y={c.y} fontFamily="IBM Plex Mono" 
          fontSize={15} fill="rgba(255,255,255,0.5)"dominantBaseline="middle" fill="#fff">{spiral[i][j]-1}</text>)                
      }
    }
  }

  // edges
  for (let i=0; i<freqTokens.length; i++) {
    let token = freqTokens[i]
    let start = coords[token]
    for (let j=0; j<cooccurrence.length; j++) {
      if (cooccurrence[j].includes(token)) {
        for (let z=0; z<cooccurrence[j].length; z++) {
          if (cooccurrence[j] != token) {
            let end = coords[cooccurrence[j][z]]
            if (end) {
              results.push(<line x1={start.x} y1={start.y} x2={end.x} y2={end.y} stroke={"rgba(255,255,255,0.1)"} strokeWidth={1} />)
            }
          }
        }
      }
    }
  }
  // nodes
  for (let i=0; i<freqTokens.length; i++) {
    let token = freqTokens[i]
    let pos = coords[token]
    results.push(<rect x={pos.x-10} y={pos.y+15} height={gridHeight*0.25} width={30} rx="1" fill="#F0A928"/>)
    results.push(<rect x={pos.x-10} y={pos.y-15} height={gridHeight*0.4} width={18*token.length} rx="5" fill="#000" />)
    results.push(<text x={pos.x} y={pos.y} fontFamily="IBM Plex Mono" 
      fontSize={20} fill="rgba(255,255,255,1)"dominantBaseline="middle">{token.toUpperCase()}</text>)
    results.push(<text x={pos.x} y={pos.y+24} fontFamily="IBM Plex Mono" 
      fontSize={15} fill="rgba(255,255,255,0.5)"dominantBaseline="middle" fill="#000">{counts[token]}</text>)
  }

  return (
      <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg">                  
          {results}
        </svg>
    )
}

function blocked(domain) {
  const blocked = new Set(["hackaday.com", "supplyframe.com", "hackaday.io", "google.com", "imgur.com"])
  return blocked.has(domain.toLowerCase().trim())
}

// render categories distribution
// function renderCategoriesAsAGrid(tags, width, height) {
//   let results = []
//   let colors = ["#1C73E8", "#F0A928", "#FF8080", "#CF92C9", "#8CC98F"]

//   let offset = 100
//   for (let i=0; i<Math.min(40,tags.length); i+=2) {
//     let name = tags[i]
//     if (blocked(name)) {
//       continue
//     }
//     let r = Math.ceil(Math.sqrt(tags[i+1]))
//     results.push(<circle cx={offset} cy={height/2} r={r} fill={colors[i%colors.length]}/>)
//     // results.push(<circle cx={offset} cy={height/2} r={5} fill={"#fff"}/>)
//     results.push(<text x={offset-r} y={height/2-140} fontFamily="IBM Plex Mono" 
//       fontSize={15} fill="rgba(255,255,255,0.5)" fill="#fff">{name.toUpperCase()}</text>)
//     results.push(<text x={offset-r} y={height/2-120} fontFamily="IBM Plex Mono" 
//       fontSize={15} fill="rgba(255,255,255,0.5)" fill="rgba(255,255,255,0.3)">{tags[i+1].toLocaleString()}</text>)

//       offset += Math.max(2*r,name.length*10)

//     if (offset > width) {
//       break
//     }
//   }

//   return (
//       <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg">                  
//           {results}
//         </svg>
//     )
// }

function getArc(r, angle, offsetAngle) {
  let circ = 2*r*Math.PI
  let length = angle/360*circ
  let offset = offsetAngle/360*circ
  return [0, offset, length, circ-length].join(" ")
  // return [400,400].join(" ")
  // return "0 100 628 628"
}

function excludedToken(term) {
  let excluded = ["hackaday", "supplyframe", "comment", "hackaday.io", "project"]
  return excluded.includes(term) || (term.length < 4)
}


function renderCategories(tags, numFound, width, height) {

  let results = []
  let center = {
    x: width/2,
    y: height/2
  }

  let currentAngle = 0

  for (let i=0; i<tags.length-1; i+=2) {
    let key = tags[i]
    let value = tags[i+1]

    if (value == 0) {
      break
    }

    if (excludedToken(key)) {
      continue
    }

    let angle = Math.ceil(value/(1+numFound)*100)
  
    let r = 100
    let stroke = 75+150*Math.random()
    results.push(<circle cx={center.x} cy={center.y} r={r} fill="none" strokeWidth={stroke} stroke={i%5 == 0 ? "#FFBD29" : "#fff"} 
        strokeDasharray={getArc(r, angle,currentAngle)}/>)

    if (currentAngle > 90 && currentAngle < 270) {
      let pt = polar_to_cartesian(r + stroke*0.7, currentAngle + angle/2, center)
      results.push(<text x={pt.x} y={pt.y} className="small" fontFamily="IBM Plex Mono" 
        fontSize={13} fill={i%5 == 0 ? "#FFBD29" :"#909092"} textAnchor="end" dominantBaseline="middle"
        transform={"rotate(" + (currentAngle + angle/2) + " " + pt.x + " " + pt.y + ") rotate(" + -180 + " " + pt.x + " " + pt.y + ")"}>{key.toUpperCase()}</text>)
    } else {
      let pt = polar_to_cartesian(r + stroke*0.7, currentAngle + angle/2, center)
      results.push(<text x={pt.x} y={pt.y} className="small" fontFamily="IBM Plex Mono" 
        fontSize={13} fill={i%5 == 0 ? "#FFBD29" :"#909092"} textAnchor="start" dominantBaseline="middle"
        transform={"rotate(" + (currentAngle + angle/2) + " " + pt.x + " " + pt.y + ") "}>{key.toUpperCase()}</text>)
    }

    currentAngle += angle + 4
    if (currentAngle >= 360) {
      break
    }

  }

  return (
      <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg">                  
          {results}
        </svg>
    )
}

function renderResults(data, query, collection) {

  let results = []
  let schema = collection.schema
  let field = schema[1]
  let colors = ["#1C73E8", "#F0A928", "#FF8080", "#CF92C9", "#8CC98F"]

  for (let i=0; i<Math.min(data.response.docs.length,50); i++) {
    let doc = data.response.docs[i]
    let title = ""
    if (doc[field]) {
      title = doc[field][0]
    }
    if (collection.name == "github") {
      results.push(
        <div className="Row">

          <a href={doc["url"]}>
            <h1 style={{"fontSize": "1.2em"}}>{doc["title"]}</h1>
            <h2 style={{"fontSize": "1em"}}>{sanitize(title)}</h2>
          </a>

          <div className="GithubMetadata">
              <span className="Dot" style={{"backgroundColor": colors[i%colors.length]}}></span>
              <span>{doc["owner"] ? doc["owner"].split("/").slice("-1") : ""} + </span>
              <span className="Muted">{doc["collaborators"] ? doc["collaborators"].length : ""} collaborators</span>
          </div>

        </div>
        )
    } else {
      results.push(
        <div className="Row">
          <a href={doc[schema[0]]}>
            <h1 style={{"fontSize": collection.name == "midjourney" ? "2em" : "1.2em"}}>{sanitize(title)}</h1>
          </a>
          <div className="ArticleMetadata">
            <h5>{doc[schema[2]]}</h5>
          </div>
          <div className="ArticleContent">{renderContent(String(doc[schema[3]]), 150)}</div>
          {(i%3 == 0) && <div className="Bar"></div>}
          {collection.name == "midjourney" && <img src={data.response.docs[i][schema[0]]} />}
        </div>
        )
    }
  }    
  if (results.length == 0) {
    results.push(<h2>No results found</h2>)
  }
  return results
}


// debug
// function App() {

//   const url = "http://localhost:8983/solr/hackaday/select?indent=true&q.op=OR&facet=true&facet.field=date&facet.limit=0&facet.field=tags&facet.limit=0&facet.field=text&facet.limit=0&sort=posted+asc&rows=0&q=*:*"
//   const {isLoading, data } = useFetch(url);

//   useEffect(() => {
//     console.log(data)
//   })

//   return (<div></div>)
// }

function queryParams(location) {
  let result = {}
  let params = location.search.replaceAll("?","").split("&")
  for (let i=0; i<params.length; i++) {
    let q = params[i].split("=")
    result[q[0]] = q[1]
  }
  return result
}

function collectionNameToID(collection) {
  for (let i=0; i<collections.length; i++) {
    if (collection == collections[i]["name"]) {
      return i
    }
  }
  return 0
}

function getCollection(location) {
  let q = queryParams(location)
  return q["collection"] ? collectionNameToID(q["collection"]) : 0
}

function getQuery(location) {
  let q = queryParams(location)
  return q["q"] ? q["q"] : "all" 
}

function valid(data) {
  return data && data.response && data.facet_counts
}

function App() {

  const location = useLocation();  

  const [activeCollection, setActiveCollection] = useState(getCollection(location));

  const [query, setQuery] = useState(getQuery(location));
  const [baseline, setBaseline] = useState(null);
  
  const [showAnalytics, setShowAnalytics] = useState(false);
  const [showGraph, setShowGraph] = useState(true);
  const [showKeywords, setShowKeywords] = useState(false);
  const [showCategories, setShowCategories] = useState(false);
  const [showTags, setShowTags] = useState(false);
  const [showLinks, setShowLinks] = useState(false);  
  const [saveHistory, setSaveHistory] = useState(false);
  

  const {isLoading, data } = useFetch(getURL(collections[activeCollection], query));
  const [graphQueue, setGraphQueue] = useState([]);
  const [cache, setCache] = useState({})

  useEffect(() => {

    if (!isLoading) {
      // initialize offset counts
      if(query == "all" && !baseline && valid(data)) {
        setBaseline(aggregateFacedCounts(data.facet_counts.facet_fields.date,'1/1/2014'))
      }

      // update cache
      if (saveHistory) {
        let counts = data.facet_counts.facet_fields.date
        let q = data.responseHeader.params.q.replaceAll("content:","")
        if ((data.response.docs.length > 0) && !cache[q]) {
          let copy = {...cache}
          copy[q] = counts
          setCache(copy)
        }
      }
    }
  })

  let selections = []
  for (let i=0; i<collections.length; i++) {
    selections.push(<a href={"?collection=" + collections[i].name} onClick = { () => setActiveCollection(i)}>{collections[i].title}</a>)
  }

  let dim = useWindowDimensions()
  let width = dim.width < 900 ? dim.width*0.9 : 900
  let height = 400
  let collection = collections[activeCollection]

  return (
      <div className="App">
        <div className="Search">
          <div className="TopNav">
            <div className="Dropdown">
              <h2>{collections[activeCollection].title} <strong>Trends</strong></h2>
              <div className="DropdownContent">
                {selections}
              </div>
            </div>
            <div className="Selector">
              <h3 onClick={() => setShowGraph(!showGraph)}>[{showGraph ? "+" : "-"}] timeline</h3>
              <h3 onClick={() => setShowAnalytics(!showAnalytics)}>[{showAnalytics ? "+" : "-"}] activity</h3>
              <h3 onClick={() => setShowKeywords(!showKeywords)}>[{showKeywords ? "+" : "-"}] keywords</h3>
              <h3 onClick={() => setShowCategories(!showCategories)}>[{showCategories ? "+" : "-"}] categories</h3>
              <h3 onClick={() => setShowTags(!showTags)}>[{showTags ? "+" : "-"}] tags</h3>
              <h3 onClick={() => setShowLinks(!showLinks)}>[{showLinks ? "+" : "-"}] links</h3>
              <h3 onClick={() => setSaveHistory(!saveHistory)}>[{saveHistory ? "+" : "-"}] history</h3>
            </div>
          </div>
          <SearchInput className="search-input" value={query} onChange={(q) => {setQuery(q)}} autoFocus={true}/>
          {showAnalytics &&
            <div className="Centered">
              <svg width={width} height={height*1.2} xmlns="http://www.w3.org/2000/svg">        
                {(!isLoading && baseline) && genGraph(data.facet_counts.facet_fields.date, baseline, width, height)}
              </svg>
            </div>
          }
          <h3>{renderQuery(query, setQuery)}</h3>
          {isLoading && <div><img src={loader} className="Loader" /><h3>LOADING</h3></div>}
          
          <h3>{!isLoading && valid(data) && data.response.numFound.toLocaleString("en-US")} results</h3>
          {!isLoading && showGraph && valid(data) && renderGraph(data.facet_counts.facet_fields.date, baseline, width, height, cache, saveHistory)}
          {!isLoading && showKeywords && valid(data) && renderKeywords(data.response.docs, collection.schema[1], width,height*1.3)}
          {!isLoading && showCategories && valid(data) && renderCategories(data.facet_counts.facet_fields[collection.category], data.response.numFound, width,height*1.5)}
          {!isLoading && showLinks && valid(data) && renderLinks(data.facet_counts.facet_fields[collection.link],data.response.numFound, width, height)}
          {!isLoading && showTags && valid(data) && renderTokens(data.facet_counts.facet_fields.text, data.response.numFound, query, setQuery)}
          <div className="Results">
            {!isLoading && valid(data) && renderResults(data, query, collections[activeCollection])}
          </div>
        </div>


      <footer>
        <div className="FooterColumn">
          <h2>[ Bitfilter Browser ]</h2>
          <p>An index & visual explorer of open online hardware community content.</p>
          <p>Search, Analyze, Think.</p>
          <p>Then move on.</p>
        </div>
        <div className="FooterColumn">
          <h2>A Sketching in Hardware 2022 Project</h2>
          <p>This is a proof-of-concept, work-in-progress demo developed for a 2022 Sketching in Hardware Talk. Not a real product.</p>
          <a href="#">Download Talk Slides</a>
        </div>
        <div className="FooterColumn">
          <h2>Contact</h2>
          <p>Aleksandar Bradic</p>
          <a href="https://twitter.com/randomwalks">@randomwalks</a>
        </div>
      </footer>

    </div>
  );

}

export default App;
