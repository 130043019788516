import logo from './logo.svg';
import data from './data.json';
import './App.css';

function bar(start, params) {
  let results = []
  for (let i=0; i<params.count; i++) {    
    results.push(<circle cx={start.x + i*params.r*4} cy={start.y} r={params.r} 
                  fill={i < params.filled ? params.active : params.inactive}/>)
  }
  return results
}

function renderParagraph(text, params) {
    let chunks = text.split(/(.{25})/).filter(x => x.length == 25);    
    let results = []
    for (let i=0; i<chunks.length; i++) {
      results.push(
            <text x={params.x} y={params.y+i*10} class="small" fontFamily="IBM Plex Mono" 
                  fontSize={8} fontWeight={400}>{chunks[i]}</text>
                  )
      }
    return results
}

function score(idx, val,params) {
  let w = params.width
  let h = params.height
  let s = Math.sqrt(w)
  let r = w/3.5*(0.8+Math.random()*0.4)
  // let r = data[idx]["description"].length
  // let r = w/3.5*(0.8+Math.random()*3.4)
  let fontSize = r/2

  return (
        <svg width={w} height={h} xmlns="http://www.w3.org/2000/svg">        
          <rect width={w} height={w} fill={params.fillTop} />
          <rect x="0" y={w} width={w} height={h-w} fill={params.fillBottom} />
          <circle cx={w/2} cy={w/2} r={r} fill="#000"/>
          
          <text x={w*0.05} y={w*0.87} class="small" fontFamily="IBM Plex Mono" fontSize={w*0.035}>TOP</text>
          <text x={w*0.05} y={w*0.91} class="small" fontFamily="IBM Plex Mono" fontSize={w*0.035}>CATEGORY</text>
          
          <text x={w/2} y={w/2+w/35} class="small" fontFamily="Heimat Stencil" 
                fontSize={r*0.7} fill="#fff" textAnchor="middle" dominantBaseline="middle">{val}</text>
          
          {bar({x:w*0.35, y:w*0.88}, {active: "#000", inactive: params.fillBottom, 
                r: params.width*0.02, count: 7, filled: Math.floor(val/10)})}

          <text x={w*0.05} y={w+fontSize} class="small" fontFamily="IBM Plex Mono" 
                fontSize={s} fontWeight={400}>{data[idx]["title"]}</text>

          {renderParagraph(data[idx]["description"], {x: w*0.05, y: w+60})}
{/*          <text x={w*0.5} y={w+20} class="small" fontFamily="IBM Plex Mono" fontSize={s-3} fontWeight="500">4,213</text>
          <text x={w*0.5} y={w+35} class="small" fontFamily="IBM Plex Mono" fontSize={s-3} fontWeight="400">projects</text>
          <text x={w*0.8} y={w+20} class="small" fontFamily="IBM Plex Mono" fontSize={s-3} fontWeight="500">4,713</text>
          <text x={w*0.8} y={w+35} class="small" fontFamily="IBM Plex Mono" fontSize={s-3} fontWeight="400">likes</text>
*/}
        </svg>
      )
}

function test(val) {
  return (
      <>
      {score((Math.random()*100).toFixed(1),{width: 150, height:450, fillTop: "#FFC000", fillBottom:"#FFF7C9"})}
      {score((Math.random()*100).toFixed(1),{width: 250, height:450, fillTop: "#0081DD", fillBottom:"#85A6FF"})}
      {score((Math.random()*100).toFixed(1),{width: 150, height:450, fillTop: "#82CF00", fillBottom:"#2BAD00"})}
      {score((Math.random()*100).toFixed(1),{width: 250, height:450, fillTop: "#0081DD", fillBottom:"#85A6FF"})}
      {score((Math.random()*100).toFixed(1),{width: 350, height:450, fillTop: "#FFC000", fillBottom:"#FFF7C9"})}
      </>
      )
}

function test2() {
  let result = []
  for (let i=0; i<512; i++) {
    let color = (Math.random() < 0.7) ? "#FFC000" : "#0081DD"
    result.push(score(i, (Math.random()*100).toFixed(1),{width: 150, height:300, fillTop: color, fillBottom:"#FFF7C9"}))
  }
  return result
}

function App() {
  return (
    <div className="App">
      <div className="Grid">
        {test2()}
      </div>
    </div>
  );
}

export default App;
