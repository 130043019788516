import React, { useState } from 'react';
import Draggable from "react-draggable";
import { v4 as uuidv4 } from "uuid";

import logo from './logo.svg';
import data from './data.json';
import links from './links.json';
import likes from './likes.json';
import github from './github.json';
import './Canvas.css';

function getColor(idx) {
  let colors = ["#CF3E86", "#20989A", "#FFBD02"]
  return colors[idx%colors.length]
}

function polygon(coords, idx, update) {
  let result = []
  result.push(<polygon points={coords.map(el => el.join(",")).join(" ")} fill={getColor(idx)} />)
  for (let i=0; i<coords.length; i++) {
    result.push( <Draggable
                    // grid={[50, 50]}
                    position={{x: coords[i][0], y: coords[i][1]}}
                    onDrag={(e, data) => { update(data.x, data.y, i)} }
                    >
                    <circle r="4" fill="#fff" />
                  </Draggable>
                        )
  }
  return result
}

function radians_to_degrees(radians)
{
  return radians * (180/Math.PI);
}

function degrees_to_radians(degrees)
{
  return degrees * (Math.PI/180);
}


function polar_to_cartesian(r, theta, offset) {
  return { 
    x: offset.x + r * Math.cos(degrees_to_radians(theta)),
    y: offset.y + r * Math.sin(degrees_to_radians(theta))
  };
}

function cartesian_to_polar({x, y}) {
  return { 
    r: Math.sqrt(x * x + y * y), 
    theta: Math.atan2(y, x) 
  };
}

function randomWord() {
  let words = ["github.com", "youtube.com", "crunchbase.com"]
  return words[Math.floor(Math.random()*words.length)]
}

function circleArt() {
  let results = []
  for (let i=0; i<360; i+=1.5) {
    let center = {x: 500, y: 500}
    let p1 = polar_to_cartesian(200, i, center)
    let p2 = polar_to_cartesian(300, i, center)
    if (i%3 == 0) {  
      results.push(<line x1={p1.x} y1={p1.y} x2={p2.x} y2={p2.y} stroke="#565659" />)
      results.push(<circle cx={p1.x} cy={p1.y} r={2} fill="#fff"/>)
      results.push(<circle cx={p2.x} cy={p2.y} r={4*Math.random()} fill="#FFBF2A"/>)
      for (let j=0; j<50; j+=10) {
        let p3 = polar_to_cartesian(300+j, i+j, center)
        results.push(<circle cx={p3.x} cy={p3.y} r={3} fill="#FFBF2A"/>) 
      }
    }
  }
  return results
}

function linkDistribution() {
  let results = []
  for (let i=0; i<360; i+=3) {
    let center = {x: 500, y: 500}
    let p1 = polar_to_cartesian(120+30*Math.random(), i, center)

    // let p2 = polar_to_cartesian(200*(1+Math.random()), i, center)
    // let diameter = 180*(1+Math.random())

    let diameter = 180*(1+(i%75)/90+ Math.random()/5)
    let p2 = polar_to_cartesian(diameter, i, center)

    let entry = links[i%links.length]
    let size = Math.log(entry.count)

    // let size = 1 + 6*Math.random()

    results.push(<line x1={p1.x} y1={p1.y} x2={p2.x} y2={p2.y} stroke="#565659" />)

    results.push(<circle cx={p1.x} cy={p1.y} r={2} fill="#fff"/>)

    results.push(<circle cx={p2.x} cy={p2.y} r={size} fill="#FFBF2A"/>)

    let pt = polar_to_cartesian(130, i, center)
    let pt2 = polar_to_cartesian(diameter+20, i, center)

    // results.push(<text x={pt.x} y={pt.y} className="small" fontFamily="IBM Plex Mono" 
    //   fontSize={10} fill="rgba(255,255,255,0.5)" textAnchor="middle" dominantBaseline="middle">{i}</text>)

    if (i > 90 && i < 270) {
      results.push(<text x={pt2.x} y={pt2.y} className="small" fontFamily="IBM Plex Mono" 
        fontSize={10} fill="#909092" textAnchor="end" dominantBaseline="middle"
        transform={"rotate(" + i + " " + pt2.x + " " + pt2.y + ") rotate(" + -180 + " " + pt2.x + " " + pt2.y + ")"}>{entry.domain.substring(0,30)}</text>)
    }  else {
      results.push(<text x={pt2.x} y={pt2.y} className="small" fontFamily="IBM Plex Mono" 
        fontSize={10} fill="#909092" textAnchor="start" dominantBaseline="middle"
        transform={"rotate(" + i + " " + pt2.x + " " + pt2.y + ") "}>{entry.domain.substring(0,30)}</text>)

    }

  }
  return results
}

function getArc(r, angle, offsetAngle) {
  let circ = 2*r*Math.PI
  let length = angle/360*circ
  let offset = offsetAngle/360*circ
  return [0, offset, length, circ-length].join(" ")
  // return [400,400].join(" ")
  // return "0 100 628 628"
}

function categoryAggregatedLinks() {
  let result = {}
  let total = 0
  for (let i=0; i<links.length; i++) {
    let category = links[i].category
    let count = links[i].count
    if (!result[category]) {
      result[category] = 0
    }
    result[category] += count
    total += links[i].count
  }
  return {
    counts: result,
    total: total
  }
}


function categoryDistribution() {

  let results = []
  let center = {
    x: 500,
    y:500
  }

  let stats = categoryAggregatedLinks()

  let sortable = Object.fromEntries(
      Object.entries(stats.counts).sort(([,a],[,b]) => b-a)
  );

  let i = 0
  let currentAngle = 0
  for (let key in sortable) {
    if (key.toLowerCase() == "self" || key == "?" || key == "Defunct") {
      continue
    }
    // console.log(key + "\t" + sortable[key] + "\t" + Math.ceil(sortable[key]/stats.total*100) + "%")
    let angle = Math.ceil(sortable[key]/stats.total*100)
    let r = 200
    let stroke = 75+150*Math.random()
    results.push(<circle cx={center.x} cy={center.y} r={r} fill="none" strokeWidth={stroke} stroke={i%5 == 0 ? "#FFBD29" : "#fff"} strokeDasharray={getArc(r, angle,currentAngle)}/>)

    
    if (currentAngle > 90 && currentAngle < 270) {
      let pt = polar_to_cartesian(r + stroke*0.7, currentAngle + angle/2, center)
      results.push(<text x={pt.x} y={pt.y} className="small" fontFamily="IBM Plex Mono" 
        fontSize={13} fill={i%5 == 0 ? "#FFBD29" :"#909092"} textAnchor="end" dominantBaseline="middle"
        transform={"rotate(" + (currentAngle + angle/2) + " " + pt.x + " " + pt.y + ") rotate(" + -180 + " " + pt.x + " " + pt.y + ")"}>{key.toUpperCase()}</text>)
    } else {
      let pt = polar_to_cartesian(r + stroke*0.7, currentAngle + angle/2, center)
      results.push(<text x={pt.x} y={pt.y} className="small" fontFamily="IBM Plex Mono" 
        fontSize={13} fill={i%5 == 0 ? "#FFBD29" :"#909092"} textAnchor="start" dominantBaseline="middle"
        transform={"rotate(" + (currentAngle + angle/2) + " " + pt.x + " " + pt.y + ") "}>{key.toUpperCase()}</text>)
    }

    i++
    currentAngle += angle + 4
    if (currentAngle >= 360) {
      break
    }
  }

  // for (let i=0; i<10; i++) {
  //   let r = 200+i*50
  //   results.push(<circle cx={center.x} cy={center.y} r={r} fill="none" strokeWidth={100} stroke="#fff" strokeDasharray={getArc(r, 45,45*i)}/>)
  //   results.push(<circle cx={center.x} cy={center.y} r={r*0.6} fill="none" strokeWidth={3*(1+i)} stroke="#fff" strokeDasharray={getArc(r*0.6, 45,45*i)}/>)
  // }

  return results

}


function matrixArt() {
  let results = []
  // let colors = ["#1C73E8", "#F0A928", "#FF8080", "#CF92C9", "#8CC98F"]
  let colors = ["#fff", "#aaa", "#555"]
  for (let i=1; i<=32; i++) {
    for (let j=1; j<=32; j++) {
      let fill = Math.random() < 0.7 ? "#21262A" : colors[Math.floor(Math.random()*colors.length)]
      results.push(<circle r="10" cx={i*25} cy={j*25} fill={fill} />)
    }
  }
  return results
}


function bigraph(width, height) {
  let results = []
  let count = 80


  // for (let i=0; i<count; i++) {
  //   for (let j=0; j<count; j++) {
  //     if (Math.random() < 0.2) {
  //       let stroke = "rgba(255,255,255,0.2)"
  //       results.push(<line x1={30} y1={10+i*8} x2={width*0.5-5} y2={10+j*8} stroke={stroke} />)
  //     }
  //     if (Math.random() < 0.2) {
  //      results.push(<line x1={width*0.5+10} y1={10+j*8} x2={width-30} y2={10+i*8} stroke="rgba(255,255,255,0.1)" />) 
  //     }
  //   }
  // }

  // for (let key in likes) {
  //   // console.log(likes[key])
  //   if (key < 30) {
  //     console.log(key + "\t" + likes[key])
  //   }
  // }

  // console.log(likes["5"])

  // for (let i=0; i<count; i++) {
  //   if (likes[i]) {
  //     let vals = likes[i]
  //     for (let j=0; j<vals.length; j++) {
  //       if (vals[j] < count) {
  //         results.push(<line x1={30} y1={10+i*8} x2={width*0.5-5} y2={10+vals[j]*8} stroke={"rgba(255,255,255,0.1"} />)
  //       }
  //     }
  //   }
  // }


  let aggregateLevel = 100;
  let ids = Object.keys(likes)
  for (let key=0; key<ids.length; key++) {
    let i = ids[key]
    let vals = likes[i]
    if (i <= 10) {
      continue
    }
    if (i < count*aggregateLevel) {      
      for (let j=0; j<vals.length; j++) {
        if (vals[j] < count*aggregateLevel) {
          results.push(<line x1={35} y1={10+Math.floor(i/aggregateLevel)*8} x2={width*0.5-5} y2={10+Math.floor(vals[j]/aggregateLevel)*8} stroke={"rgba(255,255,255,0.02"} />)
        }
      }
    } else if (i < 2 * count*aggregateLevel) {      
        i = i - count*aggregateLevel
        for (let j=0; j<vals.length; j++) {
          if (vals[j] < count*aggregateLevel) {
            results.push(<line x1={width - 40} y1={10+Math.floor(i/aggregateLevel)*8} x2={width*0.5+10} y2={10+Math.floor(vals[j]/aggregateLevel)*8} stroke={"rgba(255,255,255,0.02"} />)
          }
        }
      }

  }

  for (let i=0; i<count; i++) {

    results.push(<text x={23} y={10+i*8} className="small" fontFamily="IBM Plex Mono" 
      fontSize={7} fill="rgba(255,255,255,0.5)" textAnchor="end" dominantBaseline="middle">{i*aggregateLevel}</text>)

    results.push(<text x={width-25} y={10+i*8} className="small" fontFamily="IBM Plex Mono" 
      fontSize={7} fill="rgba(255,255,255,0.5)" textAnchor="start" dominantBaseline="middle">{count*aggregateLevel+i*aggregateLevel}</text>)

    results.push(<circle r="2" cx={30} cy={10+i*8} fill={"#fff"} />)
    results.push(<circle r="3" cx={width*0.5+2} cy={10+i*8} fill={"#fff"} />)
    results.push(<circle r="2" cx={width-32} cy={10+i*8} fill={"#fff"} />)    
  }
  return results
}


function githubCollaborators() {
  let data = github

  // console.log(github.length)

  let singleCount = 0
  let totalCount = 0
  // let uniqueCollaborators = {}

  for (let i=0; i<data.length; i++) {
    let collaborators = data[i]["collaborators"]
    if (collaborators.length > 100) {
      singleCount += 1
    }
    for (let j=0; j<collaborators.length;j++) {
      let user = collaborators[j]
      // uniqueCollaborators[user] = true
      totalCount += 1
    }
  }
  console.log("SINGLE: " + singleCount)
  console.log("TOTAL: " + totalCount)
  // console.log("CONTRIBUTORS: " + Object.keys(uniqueCollaborators).length)

  data = []
  for (let i=0; i<github.length; i++) {
    if (Math.random() < 0.1) {
      data.push(github[i])
    }
  }
  let results = []
    for (let i=0; i<360; i+=3) {
    let center = {x: 500, y: 500}
    let entry = data[i]
    
    let e = entry["title"]
    let numCollaborators = entry["collaborators"].length

    let outerLimit = Math.min(225+Math.log(1+numCollaborators)*50,400)
    let offset = Math.log(1+numCollaborators) 

    let p1 = polar_to_cartesian(200-Math.log(1+numCollaborators)*20, i, center)
    let p2 = polar_to_cartesian(outerLimit, i, center)

    let p0 = polar_to_cartesian(200-Math.log(1+numCollaborators)*20-12, i, center)
    results.push(<text x={p0.x} y={p0.y} className="small" fontFamily="IBM Plex Mono" 
      fontSize={7} fill="#C7C7C8" textAnchor="middle" dominantBaseline="middle">{numCollaborators}</text>)

    let p3 = polar_to_cartesian(outerLimit+10, i, center)
    let fill = numCollaborators > 10 ? "#FFBD29" : "#fff"
    let fill2 = numCollaborators > 10 ? "#fff" : "#C7C7C8"

    if (i > 90 && i < 270) {

      results.push(<text x={p3.x} y={p3.y} className="small" fontFamily="IBM Plex Mono" 
        fontSize={10} fill={fill2} textAnchor="end" dominantBaseline="middle"
        transform={"rotate(" + i + " " + p3.x + " " + p3.y + ") rotate(" + -180 + " " + p3.x + " " + p3.y + ")"}>{entry["title"]}</text>)

    } else {

      results.push(<text x={p3.x} y={p3.y} className="small" fontFamily="IBM Plex Mono" 
        fontSize={10} fill={fill2} textAnchor="start" dominantBaseline="middle"
        transform={"rotate(" + i + " " + p3.x + " " + p3.y + ") "}>{entry["title"]}</text>)

    }

    results.push(<line x1={p1.x} y1={p1.y} x2={p2.x} y2={p2.y} stroke="#565659" />)
    results.push(<circle cx={p1.x} cy={p1.y} r={2} fill={"#fff"}/>)
    results.push(<circle cx={p2.x} cy={p2.y} r={1+offset} fill={fill}/>)


  }
  return results
}


function Canvas() {

  return (
    <div className="Canvas">
      <div className="Grid">
       <svg height="1200" width="1000">
        {categoryDistribution()}
        </svg>
      </div>
    </div>
  );
}

export default Canvas;