import React, { useState } from 'react';
import Draggable from "react-draggable";
import { v4 as uuidv4 } from "uuid";

import logo from './logo.svg';
import data from './data.json';
import './Draw.css';

function getColor(idx) {
  let colors = ["#CF3E86", "#20989A", "#FFBD02"]
  return colors[idx%colors.length]
}

function polygon(coords, idx, update) {
  let result = []
  result.push(<polygon points={coords.map(el => el.join(",")).join(" ")} fill={getColor(idx)} />)
  for (let i=0; i<coords.length; i++) {
    result.push( <Draggable
                    // grid={[50, 50]}
                    position={{x: coords[i][0], y: coords[i][1]}}
                    onDrag={(e, data) => { update(data.x, data.y, i)} }
                    >
                    <circle r="4" fill="#fff" />
                  </Draggable>
                        )
  }
  return result
}

function App() {

  const [coords, setCoords] = useState([[50,50], [100,300], [500,200], [300,200]]);
  const [coords2, setCoords2] = useState([[250,350], [400,700], [800,600], [700,500]]);
  const [coords3, setCoords3] = useState([[550,250], [300,300], [500,400], [600,400]]);

  return (
    <div className="Draw">
      <div className="Grid">
       <svg height="1800" width="1800">
          {polygon(coords, 0, (x,y,i) => { let tmp = [...coords]; tmp[i][0] = x; tmp[i][1] = y; setCoords(tmp)}) }
          {polygon(coords2, 1, (x,y,i) => { let tmp = [...coords2]; tmp[i][0] = x; tmp[i][1] = y; setCoords2(tmp)}) }
          {polygon(coords3, 2, (x,y,i) => { let tmp = [...coords3]; tmp[i][0] = x; tmp[i][1] = y; setCoords3(tmp)}) }
        </svg>
      </div>
    </div>
  );
}

export default App;